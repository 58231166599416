export const icons = {
  userIcon: "/assets/icons/ph_user.svg",
  moneyIcon: "/assets/icons/money.svg",
  basketIcon: "/assets/icons/basket.svg",
  smileIcon: "/assets/icons/smile.svg",
  email: "/assets/icons/email.svg",
  arrow: "/assets/icons/arrow.svg",
  instagram: "/assets/icons/Instagram.svg",
  twitter: "/assets/icons/twitter.svg",
  fb: "/assets/icons/fb.svg",
  bi_bag_heart: "/assets/icons/bi_bag-heart.svg",
  carbon_data: "/assets/icons/carbon_data.svg",
  carbon_sales_ops: "/assets/icons/carbon_sales-ops.svg",
  clock: "/assets/icons/clock.svg",
  errorless: "/assets/icons/errorless.svg",
  language: "/assets/icons/language.svg",
  uii_whatsapp: "/assets/icons/ic_baseline-whatsapp.svg",
  uii_facebook: "/assets/icons/uil_facebook.svg",
  uii_web: "/assets/icons/mdi_web.svg",
  uii_instagram: "/assets/icons/mdi_instagram.svg",
  uii_shopify: "/assets/icons/ic_baseline-shopify.svg",
};
