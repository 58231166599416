import { ConnectButton } from "360dialog-connect-button";
import React from "react";

const Connect360Dialog = () => {
  return (
    <div className="flex items-center justify-center mx-auto mt-44">
      <ConnectButton
        partnerId={"TKbm2qPA"}
        callback={(callbackObject) => {
          console.log("Client ID: " + callbackObject.client);
          console.log("Channel IDs: " + callbackObject.channels);
          if (callbackObject.revokedChannels) {
            console.log(
              "Revoked Channel IDs: " + callbackObject.revokedChannels
            );
          }
        }}
        className="!bg-transparent max-xs:px-4 px-8 sm:px-10 h-16 text-primary border border-primary text-nowrap font-medium text-base sm:text-lg lg:text-xl  rounded-lg"
        label="Learn More"
        queryParameters={{
          // email: "krunk",
          // name: "krunk",
          // state: "UTTARPRADESH",
          // redirect_url: "/",
        }}
      />
    </div>
  );
};

export default Connect360Dialog;
