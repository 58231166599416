import React, { useEffect } from "react";
import Footer from "../components/shared/widgets/Footer";
import Navbar from "../components/shared/widgets/navbar/Navbar";
import { useLocation } from "react-router-dom";

const CommonLayout = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location]);

  return (
    <>
      <Navbar />
      <div className="flex flex-col justify-between">
        <main className="flex-1 min-h-screen">{children}</main>
        <Footer />
      </div>
    </>
  );
};

export default CommonLayout;
