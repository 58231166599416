import React from "react";
import "./loader.css";
const Loader = () => {
  return (
    <div className="absolute -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2 bg-white/90 z-[999] w-full h-full flex items-center justify-center">
      <div className="flex flex-col items-center rounded-lg bg-transparent">
        <div className="loader-dots relative block h-3 w-10">
          <div className="absolute top-0 mt-1 h-3 w-3 rounded-full bg-primary"></div>
          <div className="absolute top-0 mt-1 h-3 w-3 rounded-full bg-blue-400"></div>
          <div className="absolute top-0 mt-1 h-3 w-3 rounded-full bg-primary"></div>
          <div className="absolute top-0 mt-1 h-3 w-3 rounded-full bg-blue-400"></div>
        </div>
      </div>
    </div>
  );
};

export default Loader;
