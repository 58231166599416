import React from "react";
import Button from "../../../inputs/Button";
import { Link, NavLink, useLocation } from "react-router-dom";
import images from "../../../../constants/images";
const Navbar = () => {
  let month = new Date().getMonth() + 1;
  const year = new Date().getFullYear();
  if (month < 10) {
    month = "0" + month;
  }
  return (
    <nav className="w-full fixed top-0 left-0 z-[999]">
      <header className="bg-primary px-2 sm:px-14 sm:py-1 text-white flex justify-between items-center gap-6">
        <h1 className="text-lg sm:text-2xl font-semibold">Krunk.ai</h1>
        <p className="text-[10px] sm:text-base  sm:px-0">
          Give your business the boost it needs with Krunk.ai!
        </p>
      </header>
      <div className="flex px-2 sm:px-14 py-2 backdrop-blur-sm bg-white/90 justify-between items-center border-b">
        <Link to={"/"}>
          <img src={images.logo} alt="" className="w-10 sm:w-12" />
        </Link>
        <ul className="flex text-base sm:text-lg justify-center gap-4 sm:gap-10 items-center">
          <li>
            <NavLink
              to={"/solution"}
              className={({ isActive }) => isActive && "text-primary"}
            >
              Solution
            </NavLink>
          </li>
          <Link
            to={`https://calendly.com/krunk-ai/30min?month=${year}-${month}`}
          >
            <Button
              className={
                "text-base sm:text-lg gradient-btn duration-100 ease-in-out hover:hoverBtn-gradient"
              }
            >
              Book a Demo
            </Button>
          </Link>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
