import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { icons } from "../../../constants/icons";
import Button from "../../inputs/Button";
import { toast } from "react-toastify";

// const Krunkai = [
//   {
//     name: "Our Story",
//     path: "",
//   },
//   {
//     name: "Inspirations",
//     path: "",
//   },
//   {
//     name: "BLogs",
//     path: "",
//   },
// ];

const Connect = [
  {
    name: "Call: +917460010213",
    path: "tel: +917460010213",
  },
  // {
  //   name: "WhatsApp:08700986208",
  //   path: "",
  // },
  {
    name: "Contact: support@krunk.ai",
    path: "mailto:support@krunk.ai",
  },
  // {
  // }
];
const Help = [
  {
    name: "FAQ",
    path: "",
  },
  {
    name: "Privacy Policy",
    path: "/privacy-policy",
  },
  {
    name: "Terms & Conditions",
    path: "/terms&conditions",
  },
  {
    name: "Cancellation & Refund Policy",
    path: "/cancellation&refund-policy",
  },
  {
    name: "Shipping Policy",
    path: "/shipping-policy",
  },
];

const Footer = () => {
  const [email, setEmail] = useState("");
  const handleSubscription = () => {
    if (!email) {
      toast.error("Email required");
    }
    toast.success("Subscribed");
  };
  return (
    <div className="bg-footer-bg bg-no-repeat bg-cover container sm:px-14 lg:px-20 py-20 ">
      <div className=" text-primary-100 grid grid-cols-1 xl:grid-cols-2 gap-y-10 gap-x-20 justify-between">
        <main className=" grid grid-cols-2 gap-4">
          <h1 className="text-2xl">Krunk.ai</h1>
          {/* <div className="flex flex-col gap-4 pt-5  text-sm sm:text-lg">
              {Krunkai.map((item, i) => (
                <NavLink to={item.path} key={i}>
                  {item.name}
                </NavLink>
              ))}
            </div> */}
          <div>
            <h1 className="text-2xl">Connect</h1>
            <div className="flex flex-col gap-4 pt-5 text-sm sm:text-lg">
              {Connect.map((item, i) => (
                <NavLink to={item.path} key={i}>
                  {item.name}
                </NavLink>
              ))}
              <p className="">
                Mailing Address: H No.68, First Floor, Krishna Kunj Colony,
                Aurangabad,Mathura, Mathura- 281006, Uttar Pradesh
              </p>
            </div>
          </div>
        </main>
        <main className="grid grid-cols-2 gap-4 ">
          <div>
            <h1 className="text-2xl">Help</h1>
            <div className="flex flex-col gap-4 pt-5  text-sm sm:text-lg">
              {Help.map((item, i) => (
                <NavLink to={item.path} key={i}>
                  {item.name}
                </NavLink>
              ))}
            </div>
          </div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubscription();
            }}
          >
            <h1 className="text-2xl pb-2">Our Newsletter</h1>
            <p className="text-sm leading-[2rem] py-2">
              Stay informed about the latest trends:
            </p>
            <div className="bg-primary-100 py-2 px-3 rounded-lg flex items-center gap-2 mt-2">
              <img src={icons.email} alt="" className="w-6 sm:w-8" />
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(() => e.target.value)}
                required
                placeholder="Enter Email ID"
                className=" text-secondary outline-none w-full pr-4 placeholder:text-secondary text-sm sm:text-base"
              />
            </div>
            <Button
              className={
                "bg-primary-100 text-primary w-full text-center font-medium text-base sm:text-xl mt-2  py-2 sm:py-4 rounded-lg"
              }
              type="submit"
            >
              Subscribe
            </Button>
          </form>
        </main>
      </div>
      {/* <div className="flex items-center gap-6 sm:gap-8 mt-6">
        <Link to={""}>
          <img src={icons.instagram} alt="" className="w-6 sm:w-8" />
        </Link>
        <Link to={""}>
          <img src={icons.twitter} alt="" className="w-6 sm:w-8" />
        </Link>
        <Link to={""}>
          <img src={icons.fb} alt="" className="w-6 sm:w-8" />
        </Link>
      </div> */}
    </div>
  );
};

export default Footer;
