import React from "react";
// import { ConnectButton } from "360dialog-connect-button";
import CommonLayout from "./layout/CommonLayout";
import Routers from "./routes";
import { Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const App = () => {
  return (
    <CommonLayout>
      <Routers />
      <ToastContainer
        position="top-right"
        autoClose={4000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
        theme="light"
        transition={Slide}
      />
    </CommonLayout>
  );
};

export default App;
