import React from "react";
import { cn } from "../../lib/cn";

const Button = ({ className, children, ...props }) => {
  return (
    <button
      className={cn(
        "font-semibold text-white rounded-lg text-xl py-3 px-4",
        ` ${className}`
      )}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;
