const images = {
  try_bot_banner: "",
  stats_sectionImg: "/assets/images/stats-section.svg",
  stats_sectiongirlImg: "/assets/images/stat-sectiongirl.svg",
  pay_growthImg: "/assets/images/pay-growth.png",
  heroImg: "/assets/images/hero-illustration.svg",
  karagarilogoImg: "/assets/images/KARAGARI.svg",
  gamenationlogoImg: "/assets/images/gamenationlogo.svg",
  myntralogoImg: "/assets/images/myntralogo.svg",
  flurencologoImg: "/assets/images/flurencologo.svg",
  AiIlustrationImg: "/assets/images/AiIlustration.svg",
  solutionHeroImg: "/assets/images/solution-hero.svg",
  solutionMobileImg: "/assets/images/solution-mobile.svg",
  solutionMockupImg: "/assets/images/solution-mockup.svg",
  solutionBannerImg: "/assets/images/solution-banner.svg",
  tryBotBannerImg: "/assets/images/try-bot-banner-img.svg",
  logo: "/assets/images/logo.svg",
};
export default images;
