import React, { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import Loader from "./components/shared/Loader";
import Connect360Dialog from "./pages/Connect360Dialog";

const CancellationAndRefundPolicy = lazy(() =>
  import("./pages/CancellationAndRefundPolicy")
);
const ShippingPolicy = lazy(() => import("./pages/ShippingPolicy"));
const Home = lazy(() => import("./pages/Home"));
const Solution = lazy(() => import("./pages/Solution"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"));
const TermsAndConditions = lazy(() => import("./pages/TermsAndConditions"));

const Routers = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/solution" element={<Solution />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms&conditions" element={<TermsAndConditions />} />
        <Route
          path="/cancellation&refund-policy"
          element={<CancellationAndRefundPolicy />}
        />
        <Route path="/shipping-policy" element={<ShippingPolicy />} />
        <Route path="/connect-360" element={<Connect360Dialog />} />
      </Routes>
    </Suspense>
  );
};

export default Routers;
